<template>
  <div class="navbar-container d-flex content align-items-center">
    <a href="/">
      <img
        src="https://i.ibb.co/6ZDFMf9/logo.png"
        alt="logo"
        style="height: 40px"
    /></a>

    <b-navbar-nav class="nav align-items-center ml-auto inline-spacing">
      <!-- 
      <b-nav vertical>
        <b-nav-item :to="{ name: 'our-quality-and-environmental-policy' }">
          <span style="color: #4b4b4b !important">
            Kalite ve Çevre Politikamız
          </span></b-nav-item
        >
        <b-nav-item :to="{ name: 'our-mission-and-vision' }">
          <span style="color: #4b4b4b !important"> Misyon ve Vizyonumuz </span>
        </b-nav-item>
      </b-nav>  -->
      <b-nav class="inline-spacing" v-if="userData.role.includes('Dealer')">
        <b-nav-item
          :to="{ name: 'pages-account-setting' }"
          style="
            background-color: #4b4b4b;
            padding: 5px 10px;
            border-radius: 5px;
          "
        >
          <strong style="color: white !important">
            {{ userData.dealerErpName }}
          </strong>
        </b-nav-item>
      </b-nav>
      <b-nav v-else class="inline-spacing">
        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div
              class="d-sm-flex d-none user-nav"
              style="
                background-color: #4b4b4b;
                padding: 5px 10px;
                border-radius: 5px;
              "
            >
              <p
                class="user-name font-weight-bolder mb-0"
                style="color: white !important"
              >
                YÖNETİM
              </p>
            </div>
          </template>

          <b-dropdown-item
            :to="{ name: 'dealers-list' }"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Bayiler</span>
          </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'products' }"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="CheckIcon" class="mr-50" />
            <span>Ürünler</span>
          </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'campaign-editor' }"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="ArrowDownIcon" class="mr-50" />
            <span>Kampanyalar</span>
          </b-dropdown-item> 
           <b-dropdown-item
            :to="{ name: 'discounts' }"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="ArrowDownIcon" class="mr-50" />
            <span>İskontolar</span>
          </b-dropdown-item> 
        </b-nav-item-dropdown>
      </b-nav>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNav,
  BNavItem,
  BNavbarNav,
  BDropdownDivider,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
} from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import DarkToggler from "./components/DarkToggler.vue";

import SearchBar from "./components/SearchBar.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BLink,
    BDropdownDivider,
    BDropdownItem,
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    BNav,
    BNavItem,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    BNavItemDropdown,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
};
</script>

<style>
.inline-spacing > * {
  margin-right: 1.5rem;
}
</style>
