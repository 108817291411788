export default [
  {
    title: "Ürünler",
    icon: "ListIcon",
    route: "home",
  },
  {
    title: "Siparişlerim",
    icon: "CreditCardIcon",
    route: "myorders-list",
  },
  {
    title: "Bakiyelerim",
    icon: "BarChart2Icon",
    route: "orderbalances-list",
  },
  {
    title: "Cari Ekstre",
    icon: "BatteryChargingIcon",
    route: "current-extract",
  },
  {
    title: "Yeni Ürünler",
    icon: "RefreshCcwIcon",
    route: "new-products",
  },
  {
    title: "Kampanyalı Ürünler",
    icon: "ShoppingBagIcon",
    route: "campaign-products",
  },
  {
    title: "Kampanyalar",
    icon: "RssIcon",
    route: "campaigns",
  },
  {
    title: "Sepetim",
    icon: "ShoppingCartIcon",
    route: "mycarts-list",
  },
  {
    title: "Çıkış Yap",
    icon: "LogOutIcon",
    route: "redirect-official",
  },
];
